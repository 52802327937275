/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable camelcase */
import Axios from 'axios';
let BASE_URL = 'https://prod1.opiniondigest.in';

if (process.env.REACT_APP_ENV === 'production') {
  BASE_URL = 'https://api.travelnewsdigest.in';
} else if (process.env.REACT_APP_ENV === 'und-production') {
  BASE_URL = 'https://api.urbannewsdigest.in';
} else if (process.env.REACT_APP_ENV === 'oped-production') {
  BASE_URL = 'https://prod.opiniondigest.in';
} else if (process.env.REACT_APP_ENV === 'clip-production') {
  BASE_URL = 'https://api.theclipapp.com';
}

if (process.env.REACT_APP_ENV === 'und-staging') {
  BASE_URL = 'https://stage.urbannewsdigest.in';
} else if (process.env.REACT_APP_ENV === 'oped-staging') {
  BASE_URL = 'https://prod1.opiniondigest.in';
}

// const stageURL = isUndStage ? 'https://stage.urbannewsdigest.in' : 'https://prod1.opiniondigest.in';
// const BASE_URL = process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "production" ? "https://api.travelnewsdigest.in" : stageURL;
/**
 * Fetch site parse data
 * @param {String} url
 */
export const fetchExtractedData = ({ url }) =>
  Axios.post(`${BASE_URL}/dashboard/site/parse/`, {
    url,
  });

/**
 * Fetch extra categories data list
 */
export const fetchCategoriesData = () => Axios.get(`${BASE_URL}/categories/`);
export const fetchDynamicTabs = () => Axios.get(`${BASE_URL}/dynamic_tabs/`);

export const fetchTagsGroupList = () => Axios.get(`${BASE_URL}/dashboard/tag_groups/tags/`);

// export const fetchQuestionAnswerList = ({ id }) => Axios.get(`${BASE_URL}faq-question/${id}/`);

export const fetchEditorScroreText = ({ videoDigest }) =>
  Axios.get(
    `${BASE_URL}/dashboard/dynamic/text/?marcomm_page=dashboard&section=${
      videoDigest ? 'digest_video' : 'digest'
    }`,
  );

export const saveCategory = (values) => Axios.post(`${BASE_URL}/dashboard/category/`, values);

/**
 * Delete digest API
 * @param {Array} digestIds
 */
export const deleteDigestApi = ({ digestIds }) =>
  Axios.delete(`${BASE_URL}/dashboard/digests/`, {
    data: { digest_ids: digestIds },
  });

/**
 * Delete digest API
 * @param {Array} digestIds
 */
export const copyDigestApi = ({ digestId }) =>
  Axios.post(`${BASE_URL}/dashboard/digests/${digestId}/copy/`);

/**
 * Fetch digests list
 * @param {Number} page
 * @param {String} sortBy
 * @param {String} sortOrder
 * @param {String} search
 */
export const fetchDigestsData = ({
  page,
  sortBy,
  sortOrder,
  search,
  typeSelected,
  selectedStatus,
  selectedCategory,
  selectedSource,
  selectedAuthor,
  size,
  labels,
  editorScoreQuality,
  editorScoreRelevanceTerm,
  filterTags,
  filterRegions,
  // changeStats,
}) =>
  Axios.get(`${BASE_URL}/digests/`, {
    params: {
      page,
      sort_by: sortBy,
      sort_order: sortOrder,
      web_sources: selectedSource,
      authors: selectedAuthor,
      search,
      digest_types: typeSelected,
      status: selectedStatus,
      categories_or: selectedCategory,
      labels,
      size: size || 20,
      editor_score_quality: editorScoreQuality,
      editor_score_relevance_term: editorScoreRelevanceTerm,
      tags: filterTags,
      region_ids: filterRegions,
      // change_stats: changeStats
    },
  });

/**
 * Fetch digest edit data
 * @param {String} id
 */
export const fetchDigestEditData = ({ id }) => Axios.get(`${BASE_URL}/digests/${id}/`);

/**
 * Fetch extra tags list
 */
export const fetchTagsData = ({ searchString }) =>
  Axios.get(`${BASE_URL}/dashboard/tags/?search=${searchString || ''}`);

export const fetchLanguages = () => Axios.get(`${BASE_URL}/dashboard/lang/`);

export const fetchDigestTags = (content) =>
  Axios.post(`${BASE_URL}/dashboard/tags/parsed/`, content);

/**
 * Create digest data
 * @param {Object} data
 */
export const createDigest = ({ data }) => Axios.post(`${BASE_URL}/dashboard/digest/`, data);

/**
 * Edit Digest data
 * @param {Object} data
 * @param {Number} id
 */

export const updateDigest = ({ data, id }) =>
  Axios.patch(`${BASE_URL}/dashboard/digests/${id}/`, data);

export const generateFAQs = (id) => Axios.post(`${BASE_URL}/faq-question/${id}/`, {})

export const updateFAQsStatus = (id, displayStatus) => Axios.patch(`${BASE_URL}/faq-display/${id}/`, {"display": displayStatus})

export const generateAnswers = (id) => Axios.post(`${BASE_URL}/faq-answer/${id}/`, {})

export const regenerateFAQAnswerById = (digestId, questionId) => Axios.post(`${BASE_URL}/faq-answer/${digestId}/`, { "question_id": questionId })

export const updateSaveFAQQuestions = (digestId, questionsList) => Axios.post(`${BASE_URL}/faq-question/${digestId}/`, questionsList)

export const addLablesDigest = ({ data }) =>
  Axios.patch(`${BASE_URL}/dashboard/digests/labels/`, data);

/**
 * Login Api
 * @param {Object} data
 */
export const loginApi = (data) => Axios.post(`${BASE_URL}/dashboard/signin/staff/`, data);

/**
 * Fetch video summary list
 */
export const fetchVideoSummaryData = () => Axios.get(`${BASE_URL}/dashboard/video_summary/`);

/**
 * Fetch digest specific video summary
 * @param {Number} id
 */
export const fetchDigestVideoSummaryData = ({ id }) =>
  Axios.get(`${BASE_URL}/dashboard/digests/${id}/video_summary/`);

export const createVideoSummary = ({ data }) =>
  Axios.post(`${BASE_URL}/dashboard/video_summary/`, data);

/**
 * Save Video To digest
 */

export const saveDigestVideo = (id, values) =>
  Axios.patch(`${BASE_URL}/dashboard/digests/${id}/`, values);

/**
 * Fetch digest types
 */
export const fetchDigestType = () => Axios.get(`${BASE_URL}/dashboard/digest_types/`);

export const fetchSources = () => Axios.get(`${BASE_URL}/hosts/`);
export const fetchRegions = () => Axios.get(`${BASE_URL}/dashboard/regions/`);

export const editSources = ({ values }) => Axios.patch(`${BASE_URL}/hosts/${values.id}/`, values);

export const editTag = ({ values }) =>
  Axios.patch(`${BASE_URL}/dashboard/tags/${values.id}/`, values);

export const fetchVideoSummaryTags = () => Axios.get(`${BASE_URL}/dashboard/video_summary_tag/`);

export const summarizeText = (url) =>
  Axios.get(`${BASE_URL}/utils/get_automated_summary/?url=${url}`);

export const checkPlagiarismApi = ({ digestId, content, matchContent, matchTitle }) =>
  Axios.post(`${BASE_URL}/dashboard/utils/plagiarism_checker/`, {
    digest_content: content,
    digest_id: digestId,
    match_content: matchContent,
    match_title: matchTitle,
  });

export const fetchTranscriptData = ({ youtubeId }) =>
  Axios.get(`${BASE_URL}/dashboard/youtube/transcript?youtube_id=${youtubeId}`);

export const fetchPolls = ({ sortBy, sortOrder, search, page }) =>
  Axios.get(
    `${BASE_URL}/dashboard/poll/submissions/?&sort_by=${sortBy}&sort_order=${sortOrder}&search=${search}&page=${page}&page_size=20`,
  );

/**
 *
 * @param {Object} data
 */
export const storeCropImage = ({ data }) => Axios.post(`${BASE_URL}/dashboard/image/crop/`, data);

export const fetchUsers = (page, pageSize) =>
  Axios.get(
    `${BASE_URL}/dashboard/users/?dashboard_user=true&page_size=${pageSize || 10}&page=${page}`,
  );

export const fetchAllUsers = () => Axios.get(`${BASE_URL}/dashboard/users/`);

export const fetchArticlesFormat = () => Axios.get(`${BASE_URL}/dashboard/format/`);

export const fetchPermissions = () =>
  Axios.get(`${BASE_URL}/dashboard/permissions/?dashboard_user=true`);

export const updateUser = (values) =>
  Axios.patch(`${BASE_URL}/dashboard/users/${values.id}/`, values);

export const createNewUser = (values) => Axios.post(`${BASE_URL}/dashboard/user/`, values);

export const fetchDigests = (value, id) => {
  if (id)
    return Axios.get(
      `${BASE_URL}/dashboard/digests/collection/?search=${value}&exclude_digest_ids=${id}`,
    );
  return Axios.get(`${BASE_URL}/dashboard/digests/collection/?search=${value}`);
};

export const fetchFeedCategories = (page) =>
  Axios.get(`${BASE_URL}/dashboard/feed/category/?page=${page}`);

export const fetchAllFeedCategories = () => Axios.get(`${BASE_URL}/dashboard/feed/category/`);

export const deleteFeedCategories = (id) =>
  Axios.delete(`${BASE_URL}/dashboard/feed/categories/${id}/`);

export const removeTags = (data) => Axios.delete(`${BASE_URL}/dashboard/digests/labels/`, data);

export const saveFeedCategory = (title) =>
  Axios.post(`${BASE_URL}/dashboard/feed/category/`, title);

export const editFeedCategory = ({ title, id }) =>
  Axios.patch(`${BASE_URL}/dashboard/feed/categories/${id}/`, { title });

export const fetchFeedSources = (params) =>
  Axios.get(`${BASE_URL}/dashboard/feed/source/`, {
    params,
  });

export const deleteFeedSource = (id) => Axios.delete(`${BASE_URL}/dashboard/feed/sources/${id}/`);

export const updateFeedSource = ({ id, title }) =>
  Axios.patch(`${BASE_URL}/dashboard/feed/sources/${id}/`, { title });

export const fetchSourceInfo = (params) =>
  Axios.get(`${BASE_URL}/dashboard/feed/posts/`, {
    params,
  });

export const followNewsFeed = (id, categoryId) =>
  Axios.post(`${BASE_URL}/dashboard/feed/sources/follow/`, {
    feed_source: id,
    feed_category: categoryId,
  });

export const saveFeeds = (values) =>
  Axios.post(`${BASE_URL}/dashboard/feed/source/`, {
    ...values,
    feed_categories: [parseInt(values.categoryId, 10)],
  });

export const modifySlug = (value) =>
  Axios.patch(`${BASE_URL}/dashboard/digests/${value.id}/slug/`, value);

export const fetchHosts = () => Axios.get(`${BASE_URL}/dashboard/feed/hosts/`);

export const fetchFilteredHosts = (params) =>
  Axios.get(`${BASE_URL}/dashboard/feed/hosts/`, { params });

export const fetchStatuses = (page) =>
  Axios.get(`${BASE_URL}/dashboard/status/digests/?page=${page}`);

export const fetchAllStatuses = (page) => Axios.get(`${BASE_URL}/dashboard/digest/status/`);

export const saveStatus = (values) => Axios.post(`${BASE_URL}/dashboard/status/digest/`, values);

export const editStatus = (values, id) =>
  Axios.patch(`${BASE_URL}/dashboard/status/${id}/digest/update/`, values);

export const viewStatus = (id) => Axios.get(`${BASE_URL}/dashboard/status/${id}/digest/retrieve/`);

export const deleteStatus = (id) =>
  Axios.delete(`${BASE_URL}/dashboard/status/${id}/digest/destroy/`);

export const fetchInlineImageUrl = (formData) =>
  Axios.post(`${BASE_URL}/dashboard/digest/image/upload/`, formData);

export const fetchInlineVideoUrl = (formData) =>
  Axios.post(`${BASE_URL}/dashboard/digests/content-video/`, formData);
  
export const getDigestScore = (id) =>
  Axios.get(`${BASE_URL}/dashboard/categories/${id}/score_target/`);

export const fetchDailyReport = ({
  fromDate,
  toDate,
  authorUserIds,
  reviewerUserIds,
  formatIds,
  categoryIds,
  tagIds,
  typeIds,
  editorScoreQuality,
  languages,
  languageId,
}) =>
  Axios.post(`${BASE_URL}/dashboard/daily_report/`, {
    from_date: fromDate,
    to_date: toDate,
    author_user_ids: authorUserIds,
    reviewer_user_ids: reviewerUserIds,
    format_ids: formatIds,
    category_ids: categoryIds,
    tag_ids: tagIds,
    type_ids: typeIds,
    editor_score_quality: editorScoreQuality,
    report_type: 'agg',
    languages,
    language_id: languageId,
  });

export const downloadReportApi = ({ data }) =>
  Axios.post(`${BASE_URL}/dashboard/daily_report/`, {
    digest_ids: data,
  });

export const fetchQualityReport = ({ fromDate, toDate, authorUserIds }) =>
  Axios.post(`${BASE_URL}/dashboard/daily_report/`, {
    from_date: fromDate,
    to_date: toDate,
    author_user_ids: authorUserIds,
    report_type: 'agg',
    is_quality_report: true,
  });
export const verifyVideo = ({ digest_id }) =>
  new Promise((resolve, reject) => {
    Axios.post(`${BASE_URL}/dashboard/digests/${digest_id}/video_upload/verify/`, {
      data: JSON.stringify({}),
    })
      .then((res) => {
        if (res && res !== undefined) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
export const digestCreate = ({
  title,
  subtitle,
  content,
  image,
  tag,
  categories,
  source,
  type,
  default_preview_count,
  editor_score_quality_new,
  digest_video_file,
  reply_to,
}) => {
  return new Promise((resolve, reject) => {
    Axios.post(`${BASE_URL}/dashboard/digest/`, {
      title,
      subtitle,
      content,
      image,
      tag,
      categories,
      source,
      type,
      default_preview_count,
      editor_score_quality_new,
      digest_video_file,
      reply_to,
    })
      .then((res) => {
        if (res.data !== undefined) {
          resolve(res);
        } else {
          reject({});
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const handleAwsApi = ({ url, method, data }) =>
  new Promise((resolve, reject) => {
    const { fields, videoSource } = data;
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append('file', {
      name: 'video.mp4',
      uri: videoSource,
      type: 'video/mp4',
    });
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.upload.addEventListener(
      'progress',
      function (evt) {
        if (evt.lengthComputable) {
        }
      },
      false,
    );
    xhr.onload = function () {
      xhr.status === 204 ? resolve() : reject();
    };
    xhr.send(formData);
  });

export const fetchTagList = ({
  search,
  page,
  size,
  sortBy,
  sortOrder,
  selectedInternal,
  selectedSeries,
  defaultFollow,
  selectedStream,
}) =>
  Axios.get(`${BASE_URL}/dashboard/tags/?`, {
    params: {
      page,
      sort_by: sortBy,
      sort_order: sortOrder,
      is_internal: selectedInternal,
      is_series: selectedSeries,
      search,
      is_default_follow: defaultFollow,
      is_stream: selectedStream,
      include_group: true,
      size: size || 20,
    },
  });

export const deleteTagApi = ({ id }) => Axios.delete(`${BASE_URL}/dashboard/tags/${id}/`);
export const saveDigests = (ids, status) =>
  Axios.patch(`${BASE_URL}/dashboard/digests/bulk_update/`, {
    status,
    digests: ids,
  });

export const addTagsDigests = ({ digests, tags }) =>
  Axios.post(`${BASE_URL}/dashboard/digests/add/tags/`, {
    digests,
    tags,
  });

export const removeTagsDigests = ({ digests, tags }) =>
  Axios.post(`${BASE_URL}/dashboard/digests/remove/tags/`, {
    digests,
    tags,
  });

export const fetchCityList = ({ search, stateSearch }) =>
  Axios.get(
    `${BASE_URL}/dashboard/cities/?search=${search || ''}&state_search=${stateSearch || ''}`,
  );

export const createNewTagApi = (payload) => Axios.post(`${BASE_URL}/dashboard/tag/`, payload);

export const editTagApi = (payload, id) =>
  Axios.patch(`${BASE_URL}/dashboard/tags/${id}/`, payload);

export const sendNotificationApi = (formData, tagFollowed) => {
  if (tagFollowed)
    return Axios.post(
      `${BASE_URL}/notification/broadcast/notifications/?tag_followed=${tagFollowed}`,
      formData,
    );
  return Axios.post(`${BASE_URL}/notification/broadcast/notifications/`, formData);
};

export const editNotificationFullApi = (data, datetime, id) => {
  Axios.patch(`${BASE_URL}/notification/broadcasts/${id}/`, {
    notification_datas: data,
    datetime,
  });
};

export const editNotificationApi = (formData, id) =>
  Axios.patch(`https://stage.opiniondigest.in/notification/notifications/${id}/`, formData);

export const createGroup = ({ values }) => {
  Axios.post(`${BASE_URL}/notification/user_list_type/`, values);
};

export const deleteGroupApi = ({ id }) => {
  Axios.delete(`${BASE_URL}/notification/user_list_types/${id}/`);
};

export const editGroupApi = ({ values }) => {
  Axios.patch(`${BASE_URL}/notification/user_list_types/${values.id}/`, values);
};

export const uploadNotificationImage = (formData) =>
  Axios.post(`${BASE_URL}/dashboard/notification/image/upload/`, formData);

export const fetchNotificationHistory = ({
  notificationType,
  users,
  page,
  sortBy,
  sortOrder,
  channel,
  group,
  status,
  sendBy,
  startDate,
  endDate,
}) =>
  Axios.get(`${BASE_URL}/notification/broadcasts/`, {
    params: {
      page,
      notification_types: notificationType,
      sort_order: sortOrder,
      user_list_types: users,
      sort_by: sortBy,
      send_by: sendBy,
      channels: channel,
      groups: group,
      status,
      start_date_time: startDate,
      end_date_time: endDate,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

export const fetchNotificationEditData = ({ id }) =>
  Axios.get(`${BASE_URL}/notification/broadcasts/${id}/`);

export const fetchProgress = (id) => Axios.get(`${BASE_URL}/notification/broadcasts/${id}/status/`);

export const fetchUserList = ({ page }) =>
  Axios.get(`${BASE_URL}/notification/user_list_type/?page=${page}`);

export const fetchUserListFull = () => Axios.get(`${BASE_URL}/notification/user_list_type/`);

export const fetchPackages = () => Axios.get(`${BASE_URL}/dashboard/packages`);

export const fetchListDetails = ({ id, page }) =>
  Axios.get(`${BASE_URL}/notification/user_list_types/${id}/users/?page=${page}`);

export const fetchNotifUsers = () => Axios.get(`${BASE_URL}/notification/user_list/`);

export const fetchPills = () => Axios.get(`${BASE_URL}/dashboard/pill/`);

export const fetchChannelsApi = (type) =>
  Axios.get(`${BASE_URL}/notification/channel/?data_type=${type}`);

export const fetchChannelByGroup = (id) =>
  Axios.get(`${BASE_URL}/notification/group/channel/${id}/`);

export const cancelNotificationApi = (id) =>
  Axios.patch(`${BASE_URL}/notification/broadcasts/${id}/`, {
    status_change_to: 'cancel',
  });

export const fetchLabels = ({ search }) => Axios.get(`${BASE_URL}/labels/?search=${search}`);
export const collaboratorsSearch = (user) => Axios.get(`${BASE_URL}/users?page=1&search=${user}`);
export const fetchTagsGroups = () => Axios.get(`${BASE_URL}/dashboard/tag_groups/`);

export const getUrlData = (url) => Axios.patch(`${BASE_URL}/dashboard/hosts/get_create`, { url });

export const fetchHeadlinesApi = ({ page, status, id, sort_by, page_size,notification_source,source,search }) =>
  Axios.get(`${BASE_URL}/curate/incoming-notifications-list`, {
    params: {
      page,
      status,
      id,
      sort_by,
      page_size,
      notification_source,
      source,
      search
    },
  });

export const updateHeadline = ({ data, id }) =>
  Axios.post(`${BASE_URL}/curate/notification/${id}/status/`, data);

export const updateHeadlinesStatus = (data) =>
  Axios.post(`${BASE_URL}/curate/notification/status/`, data);

export const createNewDigest = (values) =>
  Axios.post('https://prodbot.getdailybrief.com/digest-notification/', values);

export const fetchNotificationSources = () => Axios.get(`${BASE_URL}/curate/notification-source`);  
export const fetchNotificationPackages = (search) => Axios.get(`${BASE_URL}/curate/notification-package`, {
  params: {
  search
  }});  

export const DeclusterHeadlinesAPI = (data) =>
    Axios.post(`${BASE_URL}/curate/headline-decluster/`, data);

export const ReclusterHeadlinesAPI = (data) =>
    Axios.post(`${BASE_URL}/curate/headline-recluster/`, data);
